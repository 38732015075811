@use "colors";
@use "typography";

.link-with-arrow {
  @include typography.cta;

  color: colors.$white;
  position: relative;

  &:hover {
    svg {
      margin-inline-start: 8px;
    }
  }

  a {
    cursor: pointer;
    outline: none !important;
    padding: 0 0 3px;

    &:active {
      border-block-end: 1px solid transparent;
    }

    &:focus {
      border-block-end: 1px solid colors.$white;
      text-decoration: none !important;
    }
  }

  svg {
    inline-size: 10px;
    inset-block-start: 0;
    margin-inline-start: 3px;
    position: relative;
    transition: 400ms ease-in-out;
  }
}
