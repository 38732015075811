@import "mixins";
@import "custom";

.class {
  align-items: center;
  border-block-end: 1px solid $gray_80;
  display: flex;
  padding: 30px 50px;

  @include media-breakpoint-down(md) {
    padding: 15px 30px;
  }

  @include media-breakpoint-down(sm) {
    display: block;
  }

  &-no-classes {
    padding: 35px 0;
    text-align: center;
  }

  &-image {
    inline-size: 10%;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &-empty {
      background-color: $black;
      block-size: 72px;
      border-radius: 50%;
      display: block;
      inline-size: 72px;
    }

    img {
      max-inline-size: 72px;
    }
  }

  &-name {
    inline-size: 25%;
    margin-inline-start: 20px;
    text-align: start;

    @include media-breakpoint-down(sm) {
      inline-size: auto;
      margin-inline-start: 0;
    }

    h4 {
      font-size: 20px;
      margin: 0;
    }
  }

  &-instructor {
    font-size: 14px;
    inline-size: 25%;
    text-align: start;

    @include media-breakpoint-down(sm) {
      inline-size: auto;
    }

    strong {
      color: $gray;
      display: inline-block;
      font-size: 11px;
      inset-block-start: -2px;
      letter-spacing: 1px;
      margin-inline-start: 5px;
      position: relative;
      text-transform: uppercase;
    }
  }

  &-time {
    font-size: 14px;
    inline-size: 20%;
    text-align: start;
    text-transform: lowercase;

    @include media-breakpoint-down(sm) {
      inline-size: auto;
    }
  }

  &-location {
    font-size: 14px;
    inline-size: 15%;
    text-align: start;

    @include media-breakpoint-down(sm) {
      inline-size: auto;
    }
  }
}

.schedule {
  background-color: #fff;
  inline-size: 100vw;
  max-inline-size: 1040px;
  position: relative;

  @include media-breakpoint-down(md) {
    inline-size: calc(100vw - 48px);
  }

  @include media-breakpoint-down(sm) {
    inline-size: calc(100vw - 24px);
  }

  &-close-button {
    background-color: transparent;
    border: 1px solid transparent;
    inset-block-start: 5px;
    inset-inline-end: 5px;
    outline: none !important;
    padding: 0;
    position: absolute;

    &:focus {
      border: 1px solid $white;
    }

    @include media-breakpoint-down(md) {
      inset-block-start: 35px;
    }
  }

  &-top {
    background-color: $black;
    color: $white;
    padding: 80px 60px 20px;

    @include media-breakpoint-down(md) {
      padding: 50px 30px 20px;
    }

    h2 {
      font-size: 104px;
      font-weight: 400;
      line-height: 1em;
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        font-size: 72px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 64px;
      }
    }
  }

  &-nav {
    background-color: $black;

    @include media-breakpoint-down(md) {
      background-color: #222;
    }

    &-list {
      list-style: none;
      margin: 0;
      padding: 37px 60px;

      @include media-breakpoint-down(md) {
        display: flex;
        justify-content: space-between;
        padding: 30px;
      }

      li {
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        margin: 0 5px;
        transition: all 300ms ease-in-out;

        @include media-breakpoint-down(md) {
          color: $white;
          font-size: 14px;
        }

        button {
          background-color: transparent;
          border: 1px solid transparent;
          border-radius: 4px;
          color: $gray_60;
          outline: none;
          padding: 7px 14px;

          @include media-breakpoint-down(sm) {
            padding: 5px 10px;
          }

          &:focus {
            border: 1px solid $white;
          }

          &:hover {
            border: 1px solid $gray_60;
          }
        }

        &.item-active {
          button {
            background-color: $white;
            color: $black;
          }

          span {
            &::after {
              background-color: #fff;
              block-size: 4px;
              border-radius: 50%;
              content: "";
              inline-size: 4px;
              margin-block-start: 25px;
              margin-inline-start: -7px;
              position: absolute;
            }
          }
        }
      }
    }
  }

  &-body {
    position: relative;

    &-date {
      border-block-end: 1px solid $gray_80;
      padding: 15px 30px;
      text-align: start;
    }

    &-loading {
      align-items: center;
      display: flex;
    }

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}
